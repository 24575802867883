import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { ForumLayout } from '../forum'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Form, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { navigate } from '@reach/router'
import { isLoggedIn } from "../../../services/auth"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { handleSubmitForumPost, handleGetForumCategories } from '../../../services/api'
import draftToHtml from 'draftjs-to-html'
import validator from 'validator'
import { WithContext as ReactTags } from 'react-tag-input'
import ReCAPTCHA from "react-google-recaptcha";


const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13,
  space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab, KeyCodes.space];


export default class post extends Component {
  async componentDidMount () {
    if (!isLoggedIn()) {
      navigate('/support/forum/')
    }
    this.forceUpdate()
  }

  UNSAFE_componentWillUpdate () {
    if (!isLoggedIn()) {
      navigate('/support/forum/', { state: { unverified: true } })
    }
  }
  render () {
    return (
      <ForumLayout location={this.props.location} fluid={false}>
        <PostForm />
      </ForumLayout>
    )
  }
}

class PostForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: "",
      category: null,
      categoryDefIndex: 0,
      tags: [],
      description: "",
      status: "OPEN",
      editorState: EditorState.createEmpty(),
      modal: false,
      name: user ? user.userData.attributes.name : "",
      id: user ? user.userData.username : "",
      email: user ? user.userData.attributes.email : "",

      titleValid: false,
      descriptionValid: false,
      formValid: false,

      isLoading: false,
      isInvalid: false,

      errorMessage: "",

      visible: false,
      captcha: "",
      recaptcha: true,
      categories: []
    }

    this.onDismiss = this.onDismiss.bind(this)
    this.toggle = this.toggle.bind(this);

    // React Tag Handlers
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  async componentDidMount () {
    let categoriesData = await handleGetForumCategories()
    this.setState({
      categories: categoriesData,
      category: categoriesData[this.state.categoryDefIndex]
    })
  }

  captchaChange = (value) => {
    const token = value

    this.setState({
      captcha: token
    }, () => {
      this.validateForm()
    })
  }

  validateInput (field, value) {
    let titleValid = this.state.titleValid
    let descriptionValid = this.state.descriptionValid

    switch (field) {
      case 'title':
        titleValid = !validator.isEmpty(value)
        break

      case 'description':
        descriptionValid = (!validator.isEmpty(value) && validator.matches(value, RegExp('^(?=.{8})')))

        break

      default:
        break
    }


    this.setState({
      titleValid: titleValid,
      descriptionValid: descriptionValid
    }, () => {
      this.validateForm()
    })
  }

  validateForm () {
    this.setState({
      formValid: this.state.titleValid && this.state.descriptionValid && !!this.state.captcha
    })
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    });
  }

  onDismiss () {
    this.setState({
      visible: !this.state.visible
    })
  }

  resetState = () => {
    this.setState({
      title: "",
      category: "",
      tags: [''],
      description: "",
      status: "OPEN",
      editorState: EditorState.createEmpty(),
      modal: false
    });
  }

  handleChange = event => {
    let id = event.target.id
    let val = event.target.value
    this.setState({
      [id]: val
    }, () => {
      this.validateInput(id, val)
    })
  }

  handleCategoryChange = event => {
    console.log(this.state.categories[event.target.value]);
    const name = event.target.id;
    const value = this.state.categories[event.target.value];
    this.setState({
      [name]: value,
      categoryDefIndex: event.target.value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    let str = this.state.description
    let title = this.state.title
    let stringCheck = this.isASCII(str) && this.isASCII(title)

    this.setState({
      isLoading: true
    })

    if (stringCheck) {
      await handleSubmitForumPost(this.state)
        .then((res) => {
          this.setState({
            isLoading: false
          })
          navigate('/support/forum/', { state: { notification: true } })
          if (typeof window !== "undefined") {
            window.location.reload()
          }
        })
        .catch(err => console.log(err))
    } else {
      this.setState({
        isLoading: false,
        visible: true,
        errorMessage: "Invalid characters were detected in the forum post you have submitted."
      })
    }


  }

  isASCII (str) {
    return RegExp('^[\x00-\x7F]*$').test(str);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }, () => {
      this.validateInput("description", draftToHtml(convertToRaw(editorState.getCurrentContent())))
    });
  };

  onContentStateChange = (contentState) => {
    this.setState({
      contentState
    });
  };


  // React Tags Handler
  handleDelete (i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition (tag) {

    if (this.isASCII(tag.name)) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
    } else {
      this.setState({
        isLoading: false,
        visible: true,
        errorMessage: "Invalid characters were detected in the tag you were trying to add."
      })
    }
  }

  renderCategoryOptions () {
    return this.state.categories.map(function (value, index) {
      return (
        <option value={index} key={index}>{value.category}</option>
      )
    });
  }


  render () {
    const { tags } = this.state;

    if (typeof window !== 'undefined') {

      return (
        <div>
          <Alert color="danger" toggle={this.onDismiss} isOpen={this.state.visible}>
            Error encountered : <br />
            {this.state.errorMessage}
          </Alert>
          <Form
            onSubmit={this.handleSubmit}
            style={{
              backgroundColor: `rgba(0,0,0,0.0575)`,
              padding: `1rem 2rem`,
              borderRadius: `3px`

            }}>
            <h3>
              Create a new Post
            </h3>
            <FormGroup>
              <Label>
                Post Title
              </Label>
              <Input
                type="text"
                id="title"
                title="Please fill in this field"
                onChange={this.handleChange}
                value={this.state.title}
                placeholder="What's on your mind? (max. 60 characters)*"
                maxLength={60}
                required
              />
            </FormGroup>

            <FormGroup >
              <Label>
                Post Category
              </Label>
              <Input
                type="select"
                id="category"
                onChange={this.handleCategoryChange}
                value={this.state.categoryDefIndex}
                required
              >
                {this.renderCategoryOptions()}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label>
                Post Description
              </Label>
              <Editor
                editorState={this.state.editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class form-control"
                toolbarClassName="toolbar-class"
                editorStyle={{
                  backgroundColor: `white`,
                  padding: `0.175rem 1rem`
                }}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                    strikethrough: { className: 'bordered-option-classname' },
                    code: { className: 'bordered-option-classname' },
                  },
                  blockType: {
                    className: 'bordered-option-classname',
                  },
                  fontSize: {
                    className: 'bordered-option-classname',
                  },
                  list: {
                    inDropdown: true
                  },
                  textAlign: {
                    inDropdown: true,
                    options: ['left', 'center', 'right'],
                  }
                }}
                hashtag={{}}
                onEditorStateChange={this.onEditorStateChange}
                onContentStateChange={this.onContentStateChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Tags
              </Label>
              <ReactTags
                classNames={{
                  tagInputField: 'form-control',
                }}
                placeholder="Add tag(s)"
                inline
                tags={tags}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                allowDragDrop={false}
                delimiters={delimiters}
                labelField={'name'}
              />
            </FormGroup>

            <FormGroup>
              <ReCAPTCHA
                sitekey={process.env.CAPTCHA_SITEKEY}
                onChange={this.captchaChange}
                ref={(r) => this.recaptcha = r}
              />
            </FormGroup>

            {/* Desktop Display */}
            <div className="d-none d-md-block text-right">
              <span>&nbsp;</span>
              <Button
                color="link"
                onClick={event => {
                  event.preventDefault()
                  this.toggle()
                }}>
                Cancel and Discard
              </Button>

              <Button
                color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
                disabled={!this.state.formValid || this.state.isLoading}
              >
                {!this.state.isLoading ? 'SUBMIT' : 'Loading...'}
              </Button>
            </div>

            <br className="d-md-none" />

            {/* Mobile Display */}
            <Button
              color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
              className="d-md-none"
              block
              disabled={!this.state.formValid || this.state.isLoading}
            >
              {!this.state.isLoading ? 'SUBMIT' : 'Loading...'}
            </Button>
            <Button color="link"
              className="d-md-none"
              block
              onClick={event => {
                event.preventDefault()
                this.toggle()
              }}>
              Cancel and Discard
            </Button>
          </Form>


          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to discard your changes?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={event => {
                  event.preventDefault()
                  navigate('/support/forum/')
                }}>
                Yes
              </Button>{' '}
              <Button color="secondary" onClick={this.toggle}>No</Button>
            </ModalFooter>
          </Modal>

        </div>
      )

    } else {
      return (
        <div>
          <Alert color="danger" toggle={this.onDismiss} isOpen={this.state.visible}>
            Error encountered : <br />
            {this.state.errorMessage}
          </Alert>
          <Form
            onSubmit={this.handleSubmit}
            style={{
              backgroundColor: `rgba(0,0,0,0.0575)`,
              padding: `1rem 2rem`,
              borderRadius: `3px`

            }}>
            <h3>
              Create a new Post
            </h3>
            <FormGroup>
              <Label>
                Post Title
              </Label>
              <Input
                type="text"
                id="title"
                title="Please fill in this field"
                onChange={this.handleChange}
                value={this.state.title}
                placeholder="What's on your mind? (max. 60 characters)*"
                maxLength={60}
                required
              />
            </FormGroup>

            <FormGroup >
              <Label>
                Post Category
              </Label>
              <Input
                type="select"
                id="category"
                onChange={this.handleCategoryChange}
                value={this.state.categoryDefIndex}
                required
              >
                {this.renderCategoryOptions()}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label>
                Post Description
              </Label>

            </FormGroup>

            <FormGroup>
              <Label>
                Tags
              </Label>
              <ReactTags
                classNames={{
                  tagInputField: 'form-control',
                }}
                placeholder="Add tag(s)"
                inline
                tags={tags}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                allowDragDrop={false}
                delimiters={delimiters}
                labelField={'name'}
              />
            </FormGroup>

            <FormGroup>
              <ReCAPTCHA
                sitekey={process.env.CAPTCHA_SITEKEY}
                onChange={this.captchaChange}
                ref={(r) => this.recaptcha = r}
              />
            </FormGroup>

            {/* Desktop Display */}
            <div className="d-none d-md-block text-right">
              <span>&nbsp;</span>
              <Button
                color="link"
                onClick={event => {
                  event.preventDefault()
                  this.toggle()
                }}>
                Cancel and Discard
              </Button>

              <Button
                color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
                disabled={!this.state.formValid || this.state.isLoading}
              >
                {!this.state.isLoading ? 'SUBMIT' : 'Loading...'}
              </Button>
            </div>

            <br className="d-md-none" />

            {/* Mobile Display */}
            <Button
              color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
              className="d-md-none"
              block
              disabled={!this.state.formValid || this.state.isLoading}
            >
              {!this.state.isLoading ? 'SUBMIT' : 'Loading...'}
            </Button>
            <Button color="link"
              className="d-md-none"
              block
              onClick={event => {
                event.preventDefault()
                this.toggle()
              }}>
              Cancel and Discard
            </Button>
          </Form>


          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
            <ModalBody>
              Are you sure you want to discard your changes?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={event => {
                  event.preventDefault()
                  navigate('/support/forum/')
                }}>
                Yes
              </Button>{' '}
              <Button color="secondary" onClick={this.toggle}>No</Button>
            </ModalFooter>
          </Modal>

        </div>
      )
    }


  }
}
