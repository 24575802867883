import React, { Component } from 'react'
import { Breadcrumb as BC, BreadcrumbItem} from 'reactstrap'

export default class Breadcrumb extends Component {

    render() {
        const queryData = this.props.loc.pathname.split('/');
        return (
            <div>
                <BC className="d-md-block d-none">
                    <BreadcrumbContent loc={queryData}/>
                </BC>
            </div>
        )

    }
}

const BreadcrumbContent = (data) => {



    const activeCnt = data.loc.length
    let path = ""
    return data.loc.map(function(val, index){

        path = path.concat(val + '/')

        // REMOVE ACCOUNT LINK
        if(val === 'account' || val === 'support'){
            return null
        }

        if(index === activeCnt - 1 && data.loc[index] === ""){
            return null
        }

        if(index === activeCnt - 2 && data.loc[index+1] === "")
        {
            return(
                <BreadcrumbItem
                    key={index}
                    active={true}
                    style={{
                        textTransform : `capitalize`
                    }}
                >
                    {data.loc[index]}
                </BreadcrumbItem>
            )
        }

        return(
            <BreadcrumbItem
                key={index}
                active={(activeCnt-1 === index) ? true : undefined}
                style={{
                    textTransform : `capitalize`
                }}
            >
                {/* SET IF TEXT OR LINK DEPENDING ON ACTIVE STATE*/}
                {(activeCnt-1 === index) ? val : (
                    <a href={val === "" ? '/' : path}>
                        {val === "" ? 'Home' : val}
                    </a>
                )}
            </BreadcrumbItem>
        )
    })
}

// const stripTrailingSlash = (str) => {
//     if(str.charAt(str.length-1) === "/"){ str = str.substr(0, str.length - 1);}
//     return str
// }